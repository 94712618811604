import Ipunjab3 from "../media/punjab3.jpg";
import Ipunjab4 from "../media/punjab4.jpg";
import Ipunjab5 from "../media/punjab5.jpg";

export const TestimonialData = [
  {
    name: "Install",
    picture: Ipunjab3,
    content:
      "Download and install Metamask wallet or any other one, and fund your wallet with Ethereum",
    label: "Getting started",
    link: "",
  },
  {
    name: "Swap",
    picture: Ipunjab4,
    content:
      "Go to Uniswap, copy the contract address above and swap as much ETH as you want!",
    label: "Swapping tokens",
    link: "",
  },
  {
    name: "Hold",
    picture: Ipunjab5,
    content:
      "After swapping, you will become a Punjab holder, now all you need to do is HODL!",
    label: "Become a Punjab",
    link: "",
  },
];
