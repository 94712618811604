import "./loading.scss";

export const Loading = () => {
  return (
    <>
      <div className="loading_container">
        <img className="image loading_image" src="/punjab2.png" alt="logo" />
        <div className="progress_bar progress_bar_styles"></div>
        <span className="fade_in_1s loading_text loading_text_styles"></span>
      </div>
    </>
  );
};
