import { useWindowWidth } from "@react-hook/window-size";
import { useLayoutEffect, useEffect, useState, useRef } from "react";
import { Triangle, Hearts } from "react-loader-spinner";
import { Fade } from "react-reveal";
import { GiToken } from "react-icons/gi";
import { ImLoop } from "react-icons/im";
import { AiFillAppstore } from "react-icons/ai";
import { SiGooglemarketingplatform } from "react-icons/si";
import { RxCross1 } from "react-icons/rx";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowDownCircleFill,
} from "react-icons/bs";
import {
  FaInstagram,
  FaTwitter,
  FaTelegram,
  FaChartArea,
  FaLock,
  FaFileContract,
} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import Testimonial from "../components/testimonial/testimonial";
import { ParcelsC } from "../components/parcels";
import IpunjabMain from "../media/punjab2.png";
import Igame1 from "../media/game1.jpg";
import Igame2 from "../media/game2.jpg";
import Igame3 from "../media/game3.jpg";
import Igame4 from "../media/game4.jpg";
import Igame5 from "../media/game5.jpg";
import Igame6 from "../media/game6.jpg";
import Igame7 from "../media/game7.jpg";
import Igame8 from "../media/game8.jpg";
import Igame9 from "../media/game9.jpg";
import Imerchandise1 from "../media/merchandise1.jpg";
import Imerchandise2 from "../media/merchandise2.jpg";
import Imerchandise3 from "../media/merchandise3.jpg";
import Inft1 from "../media/nft1.jpg";
import Inft2 from "../media/nft2.jpg";
import Inft3 from "../media/nft3.jpg";
import Ilogo from "../media/logo.jpg";
import Ipunjab3 from "../media/punjab3.jpg";
import Ipunjab4 from "../media/punjab4.jpg";
import Ipunjab5 from "../media/punjab5.jpg";
import Ipunjab6 from "../media/punjab6.jpg";
import Ipunjab7 from "../media/punjab7.jpg";
import Ipunjab8 from "../media/punjab8.jpg";
import Ipunjab9 from "../media/punjab9.jpg";
import Ipunjab10 from "../media/punjab10.jpg";
import { TestimonialData } from "../utils/content";
import { Loading } from "../components/loading/loading";

function Main() {
  const onlyWidth = useWindowWidth();

  const currentYear = new Date().getFullYear();
  const [isLoading, setIsLoading] = useState(true);
  const [responsiveState, setResponsiveState] = useState(false);
  const imagePaths = [
    IpunjabMain,
    Igame1,
    Igame2,
    Igame3,
    Igame4,
    Igame5,
    Igame6,
    Igame7,
    Igame8,
    Igame9,
    Imerchandise1,
    Imerchandise2,
    Imerchandise3,
    Ilogo,
    Inft1,
    Inft2,
    Inft3,
    Ipunjab3,
    Ipunjab4,
    Ipunjab5,
    Ipunjab6,
    Ipunjab7,
    Ipunjab8,
    Ipunjab9,
    Ipunjab10,
  ];

  useLayoutEffect(() => {
    precacheImages(imagePaths);
  }, []);

  function precacheImages(paths) {
    paths.forEach((path) => {
      const img = new Image();
      img.src = path;
    });
  }
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 5400);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const punjabRef = useRef();
  useEffect(() => {
    const handleMouseMove = (event) => {
      if (punjabRef.current) {
        const x = (event.clientX - window.innerWidth / 2) / 40;
        const y = (event.clientY - window.innerHeight / 2) / 40;
        punjabRef.current.style.transform = `translate3d(${x}px, ${y}px, 0)`;
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
  }, []);
  // Function to toggle the responsive class
  const toggleResponsive = () => {
    setResponsiveState(!responsiveState);
  };
  const [copied, setCopied] = useState(false);

  function handleCopy() {
    navigator.clipboard.writeText("0xc519978327c527a6ced39ee0a8c14389e0bbfa92");
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }
  return (
    <>
      <Loading />
      {!isLoading && (
        <div>
          <div className="parcels_mobile">
            <ParcelsC opacity={0.3} Hover={false} />
            <section>
              <div className="hero_container">
                {onlyWidth > 1200 ? (
                  <div className="navbar">
                    <a href="#story"> Story </a>
                    <a href="#how-to-buy"> How to buy </a>
                    <a href="#tokenomics"> Tokenomics </a>
                    <a href="#roadmap"> Roadmap </a>
                    <a href="#nfts"> NFTs </a>
                    <a href="#store"> Store </a>
                  </div>
                ) : (
                  <>
                    <div className="bars" onClick={toggleResponsive}>
                      {!responsiveState ? <GiHamburgerMenu /> : <RxCross1 />}
                    </div>
                    {responsiveState && (
                      <div className="navbar navbarm">
                        <a href="#story"> Story </a>
                        <a href="#how-to-buy"> How to buy </a>
                        <a href="#tokenomics"> Tokenomics </a>
                        <a href="#roadmap"> Roadmap </a>
                        <a href="#nfts"> NFTs </a>
                        <a href="#socials"> Socials </a>
                      </div>
                    )}
                  </>
                )}
                <div className="hero_container_sub">
                  <div className="hero_texts">
                    {" "}
                    <h1 className="hero_webfalling">
                      <Triangle
                        color="#4fa94d"
                        width={onlyWidth > 1200 ? "100" : "70"}
                        visible={true}
                        ariaLabel="falling-lines-loading"
                      />{" "}
                      $PUNJABPEPEINU
                    </h1>
                    <h2>
                      The Story of how a little Punjabi Boy grew up to become
                      the biggest Shit Coin Dev in history{" "}
                      <img className="logo_image" src={Ilogo} alt="logo" />
                    </h2>
                    <div className="footer_socials">
                      <a href="https://t.me/PUNJABPEPEINU">
                        <FaTelegram />
                      </a>
                      <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xc25e1caf92ea65c07d3cc3aa0620fbc832b02ba4">
                        <FaChartArea />
                      </a>
                      <a href="https://twitter.com/PunjabPepeInu">
                        <FaTwitter />
                      </a>
                      <a href="https://instagram.com/PunjabPepeInu">
                        <FaInstagram />
                      </a>
                      <a href="mailto:punjabpepeinu@protonmail.com">
                        {" "}
                        <MdEmail />
                      </a>
                    </div>
                    <a href="#store">
                      {" "}
                      <h3 style={{ color: "#fd6d6f" }}>
                        Merchandise store coming soon!
                      </h3>
                    </a>
                  </div>

                  <img
                    alt="codebloc"
                    src={IpunjabMain}
                    className="hero_main_image"
                    ref={punjabRef}
                  />
                </div>
              </div>
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 57.01"
              >
                <defs>
                  <style>{`.cls-1{fill:#2a7716;}`}</style>
                </defs>
                <title>wavy-loops-top</title>
                <path
                  class="cls-1"
                  d="M0,0H500V27a281.22,281.22,0,0,1-89,14c-34.3-.27-52.92-7-75-11C250.31,14.33,217.45,57.92,131,57c-30.2-.32-75.43-6.17-131-35"
                />
              </svg>
            </section>

            <section id="story" style={{ marginTop: "200px" }}>
              <Fade>
                <div className="story_container">
                  <h1>Our story</h1>
                  <div className="story_sub_container">
                    <img src={Ipunjab6} alt="punjab story"></img>
                    <h3>
                      A little Punjabi boy grew up to become the biggest{" "}
                      <span style={{ color: "#fd6d6f" }}> shit coin dev </span>
                      in history.
                      <br /> <br />
                      With his unwavering determination and tireless work ethic,
                      he managed to build a{" "}
                      <span style={{ color: "#fd6d6f" }}>
                        {" "}
                        loyal following of investors
                      </span>{" "}
                      and enthusiasts, who were equally excited to be a part of
                      the Shitter's success story.
                    </h3>
                  </div>
                </div>
              </Fade>
            </section>
            <section id="how-to-buy" style={{ marginTop: "200px" }}>
              <div className="home_about">
                <Fade>
                  <h1>How to buy $PUNJABPEPEINU</h1>
                  <h3>Copy the contract address</h3>
                  <div className="copy-to-clipboard">
                    <span>0xC519978327C527a6CED39eE0a8C14389e0BbFA92</span>
                    <button onClick={handleCopy}>
                      {copied ? "Copied!" : "Copy"}
                    </button>
                  </div>
                  <h3>Follow these steps</h3>
                </Fade>
                <div className="projects_subcontainer">
                  {TestimonialData.map((testimonial, i) => {
                    return (
                      <Fade key={i}>
                        <Testimonial
                          name={testimonial.name}
                          picture={testimonial.picture}
                          label={testimonial.label}
                          content={testimonial.content}
                          link={testimonial.link}
                        />
                      </Fade>
                    );
                  })}
                </div>
              </div>
            </section>
            <section id="tokenomics" style={{ marginTop: "200px" }}>
              <Fade>
                <div className="tokenomics_container">
                  <h1>Tokenomics</h1>
                  <div className="tokenomics_card">
                    <GiToken />
                    <span style={{ color: "#fd6d6f" }}> PUNJABPEPEINU </span>
                    Token Symbol
                  </div>
                  <div className="tokenomics_card">
                    <FaLock />
                    <span style={{ color: "#fd6d6f" }}> Locked </span>Liquidity
                  </div>
                  <div className="tokenomics_card">
                    <ImLoop />
                    <span style={{ color: "#fd6d6f" }}> 2% tax</span>into the LP
                  </div>
                  <div className="tokenomics_card">
                    <SiGooglemarketingplatform />
                    <span style={{ color: "#fd6d6f" }}> 2% tax</span>into
                    marketing wallet
                  </div>
                  <div className="tokenomics_card">
                    <FaFileContract />
                    <span style={{ color: "#fd6d6f" }}> Contract </span>{" "}
                    renounced
                  </div>
                  <div className="tokenomics_card">
                    <AiFillAppstore />
                    <span style={{ color: "#fd6d6f" }}>
                      {" "}
                      1,000,000,000{" "}
                    </span>{" "}
                    Total Supply
                  </div>
                </div>
              </Fade>
            </section>

            <section id="roadmap" style={{ marginTop: "200px" }}>
              <Fade>
                <div className="roadmap_container">
                  <h1>Roadmap</h1>

                  <div className="roadmap_group">
                    <div className="roadmap_column">
                      <img src={Ipunjab7} alt="punjab"></img>
                      <span> 🚀 Website Launch </span>
                      <span> 🚀 Telegram Setup </span>
                      <span> 🚀 Live Token</span>
                      <span> 🚀 Socials ready </span>
                    </div>
                    {onlyWidth < 1200 ? (
                      <BsFillArrowDownCircleFill />
                    ) : (
                      <BsFillArrowRightCircleFill />
                    )}
                    <div className="roadmap_column">
                      <img src={Ipunjab8} alt="punjab"></img>
                      <span> 🚀 1,000 holders </span>
                      <span> 🚀 CMC application and listing </span>
                      <span> 🚀 Coingecko listing</span>
                      <span> 🚀 Chicken Karahi Village Marketing Drive </span>
                    </div>
                    {onlyWidth < 1200 && <BsFillArrowDownCircleFill />}
                  </div>

                  <div className="roadmap_group">
                    <div className="roadmap_column">
                      <img src={Ipunjab9} alt="punjab"></img>
                      <span> 🚀 Exclusive PUNJABPEPEINU E-book </span>
                      <span> 🚀 10,000 Holders </span>
                      <span> 🚀 Big telegram community</span>
                      <span> 🚀 Weekly giveaways </span>
                    </div>
                    {onlyWidth < 1200 ? (
                      <BsFillArrowDownCircleFill />
                    ) : (
                      <BsFillArrowRightCircleFill />
                    )}
                    <div className="roadmap_column">
                      <img src={Ipunjab10} alt="punjab"></img>
                      <span> 🚀 NAAN Fungible Token Release (NFTs) </span>
                      <span> 🚀 Operation Talla Masala (classified) </span>
                      <span> 🚀 50,000 Holders </span>
                    </div>
                  </div>
                </div>
              </Fade>
            </section>

            <section id="store" style={{ margin: "200px 0px" }}>
              <div className="projects_container">
                <Fade>
                  <div className="nft_texts">
                    {" "}
                    <h1>Store</h1>
                    <h3>
                      Here you can see our upcoming merchandise that you can
                      shop soon!
                    </h3>
                  </div>
                </Fade>
                <div className="merchanise_images">
                  <img src={Imerchandise1} alt="merchandise" />
                  <img src={Imerchandise2} alt="merchandise" />
                  <img src={Imerchandise3} alt="merchandise" />
                </div>
              </div>
            </section>

            <section id="nfts" style={{ margin: "200px 0px" }}>
              <div className="projects_container">
                <Fade>
                  <div className="nft_texts">
                    {" "}
                    <h1>NFTs</h1>
                    <h3>
                      We are working very hard we have amazing things coming
                      like our 'NAAN' fungible Tokens
                    </h3>
                  </div>
                </Fade>
                <div className="nft_images">
                  <img src={Inft1} alt="NFT" />
                  <img src={Inft2} alt="NFT" />
                  <img src={Inft3} alt="NFT" />
                </div>
                <Fade>
                  <h3>Our game!</h3>
                </Fade>
                <Fade>
                  <div className="game_images">
                    <div className="game_container">
                      <img src={Igame1} alt="game" />
                      <p>
                        We are loading our game characters currently .. this
                        will be how Punjab Pepe looks in the game
                      </p>
                    </div>
                    <div className="game_container">
                      <img src={Igame2} alt="game" />
                      <p>
                        These are Punjab Bosses in the game if Punjab Pepe can
                        get to them he gets more energy
                      </p>
                    </div>
                    <div className="game_container">
                      <img src={Igame3} alt="game" />
                      <p>
                        These are Punjab NAAN Fungible Tokens that Punjab Pepe
                        must collect in video game
                      </p>
                    </div>
                  </div>
                </Fade>
                <Fade>
                  <div className="game_images">
                    <div className="game_container">
                      <img src={Igame4} alt="game" />
                      <p>
                        Mobile Video Game shot .. and working of fast console
                        version to start
                      </p>
                    </div>
                    <div className="game_container">
                      <img src={Igame5} alt="game" />
                      <p>A mockup of our NFT game</p>
                    </div>
                    <div className="game_container">
                      <img src={Igame6} alt="game" />
                      <p>
                        The green lambo in game view will look like this -
                        Punjab Pepe can find
                      </p>
                    </div>
                  </div>
                </Fade>
                <Fade>
                  <div className="game_images">
                    <div className="game_container">
                      <img src={Igame7} alt="game" />
                      <p>
                        Punjab attackers in game take away NAAN Fungible Tokens
                        in game Punjab Pepe must avoid
                      </p>
                    </div>
                    <div className="game_container">
                      <img src={Igame8} alt="game" />
                      <p>
                        We will add Punjab Jeet to the game that steals Punjab
                        Pepe's tokens .. he must avoid the Jeets at all times
                      </p>
                    </div>
                    <div className="game_container">
                      <img src={Igame9} alt="game" />
                      <p>
                        We are thinking of adding spicy green curry lambo to
                        game Punjab Pepe can earn
                      </p>
                    </div>
                  </div>
                </Fade>
              </div>
            </section>

            <section>
              <svg
                className="footer_svg"
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 57.01"
              >
                <defs>
                  <style>{`.cls-1{fill:#2a7716;}`}</style>
                </defs>
                <title>wavy-loops-bottom</title>
                <path
                  class="cls-1"
                  d="M500,200H0V173a281.22,281.22,0,0,1,89-14c34.3.27,52.92,7,75,11,85.69,15.67,118.55-27.92,205-27,30.2.32,75.43,6.17,131,35"
                  transform="translate(0 -142.99)"
                />
              </svg>

              <div id="socials" className="footer_container">
                <div className="footer_heartsandformtext">
                  <Hearts
                    height="150"
                    width="2000"
                    color="#ffff"
                    ariaLabel="hearts-loading"
                    wrapperStyle={{}}
                    wrapperClass="footer_hearts"
                    visible={true}
                  />
                  <span className="footer_formText">
                    Much love from our community
                  </span>
                  <span className="footer_formText">
                    Email us: punjabpepeinu@protonmail.com
                  </span>

                  <div className="footer_socials footer_socials_2">
                    <a href="https://t.me/PUNJABPEPEINU">
                      <FaTelegram />
                    </a>
                    <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xc25e1caf92ea65c07d3cc3aa0620fbc832b02ba4">
                      <FaChartArea />
                    </a>
                    <a href="https://twitter.com/PunjabPepeInu">
                      <FaTwitter />
                    </a>
                    <a href="https://instagram.com/PunjabPepeInu">
                      <FaInstagram />
                    </a>
                  </div>
                </div>

                <div className="footer_copyright">
                  {" "}
                  © {currentYear} - PUNJABPEPEINU Powered by the Community
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
}
export default Main;
